import { useState, useEffect } from "react"

export default props => {
    // let [ load, setLoad ] = useState( false );

    // useEffect(() => {
    //     if ( !load ) {
    //         setLoad(true);
    //     }
    // }, [load])

    // useEffect(() => {
    //     if(load) {
    //         setLoad(false);
    //     }
    // }, [props.reset])
    
  return (
      <div className="radio-wrapper">
        <label for={props.name}>{props.text}</label>
        <input type="radio" name={props.name} value={props.text} checked={props.value == props.text} onChange={ e => { props.setFunction(e.target.value) }} />
      </div>
  )
}
