import React, { useState, useEffect } from "react"

const Banner = ( { video } ) => {


    useEffect(() => {
        console.log(video)
    }, [video])

    return (
        <section class="banner">
            <div className="container">
                <div className="bg-wrapper">
                    {/* <img src={bannerImg} alt="ZOSTAŃ ELEKTROMONTARZYSTĄ" /> */}
                    {video &&
                        <video width="1920" height="550" autoPlay loop muted playsInline>
                            <source src={video} type="video/mp4" />
                        </video>
                    }
                </div>
                <div className="text-wrapper">
                    <h2>Jest praca dla elektromontera!</h2>
                </div>
            </div>
        </section>
    )

}



export default Banner;