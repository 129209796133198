import React, { } from "react"

const Boxes = ( ) => {
    let boxes = [
        {
            title: "Co zyskujesz:",
            desc: `<ul>
                <li>stabilne zatrudnienie: umowa o pracę w dużej, stabilnej i perspektywicznej firmie,</li>
                <li>premia za dobre wyniki,</li>
                <li>świadczenia socjalne (m. in. bony świątecznie, dofinansowanie wypoczynku),</li>
                <li>ubezpieczenie na życie,</li>
                <li>dofinansowanie zajęć sportowych,</li>
                <li>dofinansowanie szkoleń i kursów.</li>
            </ul>`
        },
        {
            title: "Nasze wymagania:",
            desc: `<ul>
                <li>ukończone 18 lat,</li>
                <li>wykształcenie min. zawodowe (mile widziane umiejętności ślusarskie lub z zakresu elektryki),</li>
                <li>umiejętność pracy w zespole,</li>
                <li>działanie z zaangażowaniem,</li>
                <li>sumienność, dokładność i odpowiedzialność.</li>
            </ul>`
        },
        {
            title: "Twój zakres obowiązków:",
            desc: `<ul>
                <li>udział w naprawach i przeglądach technicznych taboru tramwajowego,</li>
                <li>naprawa podzespołów tramwajowych,</li>
                <li>regeneracja części tramwajowych,</li>
                <li>konserwacje, naprawy, remonty i modernizacji elementów elektroenergetyki trakcyjnej,</li>
                <li>usuwanie zakłóceń i awarii na urządzeniach elektroenergetycznych średniego i niskiego napięcia,</li>
                <li>wykonywanie czynności łączeniowych na urządzeniach do 15 kV.</li>
            </ul>`
        }
    ]
    return (
        <section class="boxes">
            <div className="container">
                <div className="boxes-wrapper">
                    {boxes && boxes.map((box, index) => (
                        <div className="box">
                            <div className="icon">
                                <p>{++index}</p>
                            </div>
                            <div className="title">
                                <h3>{box.title}</h3>
                            </div>
                            <div className="desc" dangerouslySetInnerHTML={ { __html: box.desc } } />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )

}



export default Boxes;